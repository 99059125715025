var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.confirmation")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("list-of-amendments-will-process")))]), _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code-1")))]), _c('th', [_vm._v(_vm._s(_vm.$t("trusts")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._m(0)])]), _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code-2")))]), _c('th', [_vm._v(_vm._s(_vm.$t("business-partner")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._m(1)])]), _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code-3")))]), _c('th', [_vm._v("BANK")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._m(2)])]), _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code-4")))]), _c('th', [_vm._v(_vm._s(_vm.$t("cooperative")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._m(3)])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [_c('tr', [_c('td'), _c('td'), _c('td'), _c('td')])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [_c('tr', [_c('td', [_vm._v("1")]), _c('td', [_vm._v("A")]), _c('td', [_vm._v("BANK SENDIRIAN BERHAD")]), _c('td', [_vm._v("8")])]), _c('tr', [_c('td', [_vm._v("2")]), _c('td', [_vm._v("RK-KFH")]), _c('td', [_vm._v("FKF AWARIS SOLUTIONS")]), _c('td', [_vm._v("1")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [_c('tr', [_c('td', [_vm._v("1")]), _c('td', [_vm._v("RK-KFH")]), _c('td', [_vm._v("KUWAIT FINANCE HOUSE")]), _c('td', [_vm._v("1")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [_c('tr', [_c('td', [_vm._v("1")]), _c('td', [_vm._v("RK- KEKAL")]), _c('td', [_vm._v("KOPERASI PENDIDIKAN USAHAWAN BERHAD")]), _c('td', [_vm._v("1")])])]);
}]

export { render, staticRenderFns }